$(document).on("click", '.fav-icon', function(event) {

    var dataId = $(this).data( "id" );
    var ajaxUrl = "/ajax/fav?id=" + dataId;
    var favCounter = $(".header-wishlist .circle-wishlist-counter").text();

    console.log("fav-icon: " + dataId);
    console.log("ajaxUrl: " + ajaxUrl);
    console.log("favCounter: " + favCounter);

    $.ajax({
        type: "GET",
        url: ajaxUrl,
        error: function (result) {
            //    console.log("error result");
            //    console.log(result);
        },
        success: function (result) {
            console.log("success result");
            console.log(result);
            //console.log("newCounter:" + newCounter);
            if ($('.fav-icon-' + dataId).hasClass('active')) {
                console.log("remove");
                console.log("dataId: " + dataId);
                $('.fav-icon-' + dataId).removeClass('active');
                var newCounter = favCounter*1 - 1;
                $(".header-wishlist .circle-wishlist").text(newCounter);
                // console.log("favCounter: " + favCounter);
                if (newCounter > 0) {
                    $('.data-id-'+ dataId).remove();
                    $('.headerswitch').addClass('col-wishlist-active');
                    $('.header-wishlist').removeClass('d-none');
                } else {
                    $('.data-id-'+ dataId).remove();
                    $('.headerswitch').removeClass('col-wishlist-active');
                    $('.header-wishlist').addClass('d-none');
                }
            } else {
                 console.log("add");
                $('.fav-icon-' + dataId).addClass('active');
                $('.header-wishlist').removeClass('d-none');
                $('.headerswitch').addClass('col-wishlist-active');
                var newCounter = favCounter*1 + 1;
                $(".header-wishlist .circle-wishlist").text(newCounter);
            }

        },

        complete: function () {
//                console.log("fav-icon: complete");
//                console.log("wishlist Cookie: ");
        }
    });

});

function loadWishlist(ajaxUrl) {

}

$(document).ready(function(){

    /* wishlist anzeigen */
    $('.header-wishlist').click(function(event) {
        event.stopPropagation();


        var ajaxUrl = "/ajax/wishlist";

        $.ajax({
            type: "GET",
            url: ajaxUrl,
            success: function(result){
                console.log("aktion loadProducts()::success");
                $('.wishlistwrapper').html(result);
            },
            complete: function () {


                $('.wishlist-container').toggleClass("active");
                if ($('.wishlist-container').hasClass('active')) {
                    /* filter schliessen bei click outside */
                    $('body').addClass('showwishlist');
                } else {
                    $('body').removeClass('showwishlist');
                }


            }

        });

    });


    /* filter schliessen bei click outside */
    $('.superfilter').on('click',function(){
        if ($('body').hasClass('showwishlist')) {
            console.log("superfilter clicked");
            console.log("filter schliessen bei click outside");
            $('.wishlist-container').removeClass('active');
            $('body').removeClass('showwishlist');
        }
    });

    /* filter schliessen bei click button-wishlist-close */
    $('.button-wishlist-close').on('click',function(event){
        console.log("filter schliessen bei click button-wishlist-close");
        $('.wishlist-container').removeClass('active');
        $('body').removeClass('showwishlist');
    });

    /* produkt aus liste löschen */
    $('.wishlist-row .btn-cross').on('click',function(){

            var dataId = $(this).data( "id" );
            var favCounter = $(".header-wishlist .circle-wishlist-counter").text();

            console.log("produkt aus liste löschen click .fav-icon.btn-cross");
            console.log("fav-icon: " + dataId);
            console.log("favCounter: " + favCounter);

            $('.wishlist-row.data-id-' + dataId).hide();


            var dataId = $(this).data( "id" );
            var ajaxUrl = "/ajax/fav?id=" + dataId;
            var favCounter = $(".header-wishlist .circle-wishlist-counter").text();

            console.log("fav-icon: " + dataId);
            console.log("ajaxUrl: " + ajaxUrl);
            console.log("favCounter: " + favCounter);

            $.ajax({
                type: "GET",
                url: ajaxUrl,
                error: function (result) {
                    //    console.log("error result");
                    //    console.log(result);
                },
                success: function (result) {
                    console.log("success result");
                    console.log(result);
                    console.log("newCounter:" + newCounter);
                    if ($('.fav-icon-' + dataId).hasClass('active')) {
                        // console.log("remove");
                        $('.fav-icon-' + dataId).removeClass('active');
                        var newCounter = favCounter*1 - 1;
                        $(".header-wishlist .circle-wishlist").text(newCounter);
                        // console.log("favCounter: " + favCounter);
                        if (newCounter > 0) {
                            $('.headerswitch').addClass('col-wishlist-active');
                            $('.header-wishlist').removeClass('d-none');
                        } else {
                            $('.headerswitch').removeClass('col-wishlist-active');
                            $('.header-wishlist').addClass('d-none');
                        }
                    } else {
                        // console.log("add");
                        $('.fav-icon-' + dataId).addClass('active');
                        $('.header-wishlist').removeClass('d-none');
                        $('.headerswitch').addClass('col-wishlist-active');
                        var newCounter = favCounter*1 + 1;
                        $(".header-wishlist .circle-wishlist").text(newCounter);
                    }

                },

                complete: function () {
    //                console.log("fav-icon: complete");
    //                console.log("wishlist Cookie: ");
                }
            });




        });

});


function buttonWishlistClose() {
    console.log("filter schliessen bei click button-wishlist-close");
    $('.wishlist-container').removeClass('active');
    $('body').removeClass('showwishlist');
}
function findLongestWord(str) {
    var longestWord = str.split(' ').sort(function(a, b) { return b.length - a.length; });
    return longestWord[0].length;
}

$(document).ready(function(){
    // $("#coModal").modal('show');
    setTimeout(function(){
        if(!Cookies.get('modalShown')) {
            $("#coModal").modal('show');
            Cookies.set('modalShown', true);
        }
    },3000);
});

$(document).ready(function(){

    // slider
    if (window.matchMedia("(min-width: 769px)").matches) {

        /*
        $( ".headerimage-wrapper .slideshow-image" ).each(function( index ) {


            if (window.matchMedia("(min-width: 769px) and (max-width: 992px)").matches) {
                var imageurl = $(this).data( "img992" );
            } else if (window.matchMedia("(min-width: 993px) and (max-width: 1200px)").matches) {
                var imageurl = $(this).data( "img1200" );
            } else if (window.matchMedia("(min-width: 1201px) and (max-width: 1600px)").matches) {
                var imageurl = $(this).data( "img1600" );
            } else if (window.matchMedia("(min-width: 1601px)").matches) {
                var imageurl = $(this).data( "imgfull" );
            }

            $(this).css("background-image", "url(" + imageurl + ")");

        });
        */

    }

    $( ".titel-orig" ).each(function( index ) {
        var longestWord = findLongestWord($( this ).text());
        if (longestWord < 19) {
            $( this ).next().hide();
            $( this ).show();
        }
    });

    // navi burger mobile
    $('.navbar-toggler').click(function() {
        $(this).toggleClass("open");
    });

    $(".btn-cross").click(function() {
        if ($(this).hasClass('active')) {
            $(".btn-cross").removeClass('active');
        } else {
            $(".btn-cross").removeClass('active');
            $(this).toggleClass('active');
        }
        $(".slider").slick("refresh");
        $($(this).attr('href')).find('.slider').slick({
        });
    });


    // back-to-top
    $(window).on('scroll', function() {
        if ($(window).scrollTop() > 300) {
            $(".back-to-top").show();
        } else {
            $(".back-to-top").hide();
        }

        if ( $( ".showArrow" ).length ) {
            var showArrowOffset = $(".showArrow").offset().top - 300;
            if ($(window).scrollTop() > showArrowOffset) {
                $(".pfeiluntenwrapper").addClass('active');
            } else {
                $(".pfeiluntenwrapper").removeClass('active');
            }
        }

    });

    // shopnavi
    $(document).on('click', '.dropdown-menu-shop.dropdown-menu .dropdown-item span', function (e) {
        e.stopPropagation();
    });

    $('.shop-link').click(function(e) {

        var link = $(this).data( "link" );

        $('.box-shop-link').hide();
        $('.shop-link').removeClass('hover');

        $('.box-' + link).show();
        $('.' + link).addClass('hover');

    });

    /*
    $('.shop-link-uhren').click(function(e) {
        $('.box-shop-link-schmuck').hide();
        $('.shop-link-schmuck').removeClass('hover');
        $('.box-shop-link-uhren').show();
        $('.shop-link-uhren').addClass('hover');
    });
    $('.shop-link-schmuck').click(function(e) {
        $('.box-shop-link-uhren').hide();
        $('.shop-link-uhren').removeClass('hover');
        $('.box-shop-link-schmuck').show();
        $('.shop-link-schmuck').addClass('hover');
    });
     */



    $('.remove-button').click(function() {
        var id = $(this).data( "id" );
        $('.cb-' + id).prop('checked', true);
        $( "#form-cart" ).submit();
    });

    if($("#paymentMethod").length > 0) {
        var selected =  $('#paymentMethod option:selected').val();
        $( ".paymentinfo" ).hide();
        var selected = selected.replace("gatewayId:", "");
        $( ".paymentinfo-" + selected ).show();
    }

    // navi-img
    $('li.dropdown-item').hover(function() {
        var imageurl = $(this).data( "imageurl" );
        var slug = $(this).data( "slug" );
        var title = $(this).text();
        var link = $(this).data( "link" );
        if ( imageurl ) {
            /*
            console.log(imageurl);
            console.log(slug);
            console.log(title);
            console.log("link:" +link);
            */
            $(".navibild-" + slug).attr("src", imageurl);
            $(".navititle-" + slug).text(title);
            $(".navilink-" + slug + " a").attr("href", link);
        }
    });

    $('.slider-wide.slider-uhren').slick({
        prevArrow: '<div class="prev"><img alt="Slide nach links" src="/images/pfeil-links.svg"></div>',
        nextArrow: '<div class="next"><img alt="Slide nach rechts" src="/images/pfeil-rechts.svg"></div>',
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-wide.slider-schmuck').slick({
        prevArrow: '<div class="prev"><img alt="Slide nach links" src="/images/pfeil-links.svg"></div>',
        nextArrow: '<div class="next"><img alt="Slide nach rechts" src="/images/pfeil-rechts.svg"></div>',
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-small.slider-uhren').slick({
        prevArrow: '<div class="prev"><img alt="Slide nach links" src="/images/pfeil-links.svg"></div>',
        nextArrow: '<div class="next"><img alt="Slide nach rechts" src="/images/pfeil-rechts.svg"></div>',
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1
    });
    $('.slider-small.slider-schmuck').slick({
        prevArrow: '<div class="prev"><img alt="Slide nach links" src="/images/pfeil-links.svg"></div>',
        nextArrow: '<div class="next"><img alt="Slide nach rechts" src="/images/pfeil-rechts.svg"></div>',
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-product-details').slick({
        prevArrow: '<div class="prev"><img alt="Slide nach links" src="/images/pfeil-links.svg"></div>',
        nextArrow: '<div class="next"><img alt="Slide nach rechts" src="/images/pfeil-rechts.svg"></div>',
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.headerslider').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: '<div class="prev d-none d-md-block"><img alt="Slide nach links" src="/images/pfeil-links.svg"></div>',
        nextArrow: '<div class="next d-none d-md-block"><img alt="Slide nach rechts" src="/images/pfeil-rechts.svg"></div>',
        arrows: true,
        dots: true,
        customPaging: function(slider, i) {
            return '<div class="pager__item" id=' + i + '></div>';
        },
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'ease-in-out',
        lazyLoad: 'progressive',
        fade: true,
        waitForAnimate: false,
    });


    // On after slide change
    $('.headerslider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        $('.count-dots-numbers').removeClass('active');
        $('.count-dots-numbers-' + (currentSlide + 1)).addClass('active');
    });

    $(".count-dots-numbers").click(function(e){
        var slideno = $(this).data('slide');
        $('.headerslider').slick('slickGoTo', slideno - 1);
        $('.count-dots-numbers').removeClass('active');
        $(this).addClass('active');
    });

    $('.sliderNeuheiten').slick({
        prevArrow: '<div class="prev d-block"><img alt="Slide nach links" src="/images/pfeil-links.svg"></div>',
        nextArrow: '<div class="next d-block"><img alt="Slide nach rechts" src="/images/pfeil-rechts.svg"></div>',
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.sliderLazyLoad').on('lazyLoaded', sliderLazyLoad);


    function sliderLazyLoad(e, slick, image, imageSource) {
        image.attr('src',''); //remove source
        image.parent().css('background-image','url("'+imageSource+'")'); //add background to parent div
    }

    $('.slider-product-details-navi a[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.slider-product-details').slick('slickGoTo', slideno - 1);
    });


    $('.adress-switcher-wrapper').click(function(e) {
        if ($('.adress-switcher-wrapper .adress-switcher').is(":checked")) {
            $('#shippingAddressSameAsBilling').prop('checked', false);
            $('.col-adresses .ShippingAddress').show();
        } else {
            $('#shippingAddressSameAsBilling').prop('checked', true);
            $('.col-adresses .ShippingAddress').hide();
        }
    });

    $('.adress-switcher').click(function(e) {
        if ($('.adress-switcher').is(":checked")) {
            $('#shippingAddressSameAsBilling').prop('checked', false);
            $('.col-adresses .ShippingAddress').show();
        } else {
            $('#shippingAddressSameAsBilling').prop('checked', true);
            $('.col-adresses .ShippingAddress').hide();
        }
    });




    $('.gift-switcher-wrapper').click(function(e) {
        if ($('.gift-switcher-wrapper .gift-switcher').is(":checked")) {
            $('.gift-switcher-text').val('Bestellung ist Geschenk');
        } else {
            $('.gift-switcher-text').val('');
        }
    });

    $('.gift-switcher').click(function(e) {
        if ($('.gift-switcher').is(":checked")) {
            $('.gift-switcher-text').val('Bestellung ist Geschenk');
        } else {
            $('.gift-switcher-text').val('');
        }
    });




    $('.radio-paymentMethod').click(function(e) {
        var gatewayId = $(this).val();
        var gatewayId = gatewayId.replace("gatewayId:", "");
        $('.input-gatewayId').val(gatewayId);
    });


    $('.button-bezahlen').click(function(e) {
        if ($('#agb').is(":checked")) {
            $('.row-radio-agb').removeClass('error');
            $('.click-agb').hide();
        } else {
            $('.row-radio-agb').addClass('error');
            $('.click-agb').show();
            e.preventDefault();
        }
    });

    $('#agb').click(function(e) {
        if ($('#agb').is(":checked")) {
            $('.row-radio-agb').removeClass('error');
            $('.click-agb').hide();
        }
    });

    $('.trckfb').click(function(e) {
        if ($('#agb').is(":checked")) {
            console.log("trckfb");
            var gatewayId = $("input[name='gatewayId']:checked").val()
            if (gatewayId == 2) {
                //console.log("gatewayId:"+gatewayId);
                var price = $(".trckfb").data( "price" );
                //console.log("price:"+price);
                fbq('track', 'Purchase', {currency: "CHF", value: price});

                dataLayer.push({
                    'currency': "CHF",
                    'gesamtwert': price
                });

            }
        }
    });

    $('.trckkkfb').click(function(e) {
        console.log("trckkkfb");
        var price = $(".trckkkfb").data( "price" );
        console.log("price:"+price);
        fbq('track', 'Purchase', {currency: "CHF", value: price});

        dataLayer.push({
            'currency': "CHF",
            'gesamtwert': price
        });
    });


        /*
          $('.navbar').click(function() {
              $('.navbar').addClass('hidden');
              $('nav').removeClass('hidden');
          });
           $('.close').click(function() {
              $('.navbar').removeClass('hidden');
              $('nav').addClass('hidden');
          });
           $('nav a').click(function() {
              $('.navbar').removeClass('hidden');
              $('nav').addClass('hidden');
          });

           $('.readmore').click(function() {
            if ($(this).hasClass("chevronbottom")) {
              $(this).next(".content_hidden").addClass("content_shown");
              $(this).next(".content_hidden").removeClass("content_hidden");
              $(this).removeClass("chevronbottom");
            } else {
              $(this).next(".content_shown").addClass("content_hidden");
              $(this).next(".content_shown").removeClass("content_shown");
              $(this).addClass("chevronbottom");
            }
          });

          // blognavi mobiel
          if (window.matchMedia("(max-width: 899px)").matches) {

          }

        */

});

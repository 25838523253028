$(document).ready(function(){

    const cons = 20;

    $( function() {

        var minPreis = $('.preisRange').data( "minpreis" );
        var maxPreis = $('.preisRange').data( "maxpreis" );

        var entryId = $('body').data( "id" );
        var showSetFilter = 0;
        var cookieMin = minPreis;
        var cookieMax = maxPreis;
        if (entryId > 0) {
            for (key in Cookies.get()) {
                if (!key.indexOf("fc-"+entryId+"-preis-")) {
                    if (cons > 1) {
                        console.log("function readPreisCookies() : key : " + key);
                    }
                    var cookieStr = key.split('-preis-');
                    if (cookieStr[1] == "min") {
                        var cookieMin = Cookies.get('fc-' + entryId + '-preis-min');
                    }
                    if (cookieStr[1] == "max") {
                        var cookieMax = Cookies.get('fc-' + entryId + '-preis-max');
                    }
                }
            }
        }

        if (cons > 1) {
            console.log("minPreis: " + minPreis);
            console.log("maxPreis: " + maxPreis);
            console.log("minPreisCookies: " + cookieMin);
            console.log("maxPreisCookies: " + cookieMax);
        }

        $( ".filterauswahl-preis #slider-range" ).slider({
            range: true,
            min: minPreis,
            max: maxPreis,
            values: [ cookieMin, cookieMax ],
            slide: function( event, ui ) {
                $( ".filterauswahl-preis #min" ).val( ui.values[ 0 ] );
                $( ".filterauswahl-preis #max" ).val( ui.values[ 1 ] );
            },
            stop: function(event, ui) {
                setFilterCookiePreis($( ".filterauswahl-preis #min" ).val(), "min");
                setFilterCookiePreis($( ".filterauswahl-preis #max" ).val(), "max");
                checkfilter();
            }
        });
        $( ".filterauswahl-preis #min" ).val( $( ".filterauswahl-preis #slider-range" ).slider( "values", 0 ) );
        $( ".filterauswahl-preis #max" ).val( $( ".filterauswahl-preis #slider-range" ).slider( "values", 1 ) );

        var minDM = parseFloat($('.durchmesserRange').data( "mindurchmesser" ));
        var maxDM = parseFloat($('.durchmesserRange').data( "maxdurchmesser" ));

        if (cons > 1) {
            console.log("minDM: " + minDM);
            console.log("maxDM: " + maxDM);
        }

        $( ".filterauswahl-durchmesser #slider-range" ).slider({
            range: true,
            min: minDM,
            max: maxDM,
            values: [ minDM, maxDM ],
            slide: function( event, ui ) {
                $( ".filterauswahl-durchmesser #minDurchmesser" ).val( ui.values[ 0 ] );
                $( ".filterauswahl-durchmesser #maxDurchmesser" ).val( ui.values[ 1 ] );
            },
            stop: function(event, ui) {
                  checkfilter();
            }
        });

        if (cookieMin != minPreis || cookieMax != maxPreis) {
            if (cons > 1) {
                console.log("checkfilter Preis");
            }
            checkfilter();
            showSmallFilter();
        }
        /*
        $( ".filterauswahl-durchmesser #minDurchmesser" ).val( $( ".filterauswahl-durchmesser #slider-range" ).slider( "values", 0 ) );
        $( ".filterauswahl-durchmesser #maxDurchmesser" ).val( $( ".filterauswahl-durchmesser #slider-range" ).slider( "values", 1 ) );
        */

    } );

    /* cookies in den filtern gesetz? */
    function readCookies() {
        if (cons > 0) {
            console.log("function readCookies()");
        }
        var entryId = $('body').data( "id" );
        var showSetFilter = 0;
        if (entryId > 0) {
            if (cons > 1) {
                console.log("function readCookies() : entryId > 0");
            }
            for (key in Cookies.get()) {
                if (!key.indexOf("fc-"+entryId+"-")) {
                    if (cons > 1) {
                        console.log("function readCookies() : key : " + key);
                    }
                    var cookieStr = key.split('-fv-');
                    var filter = cookieStr[1];
                    var filterStr = cookieStr[0].split('-');
                    var filterlabel = filterStr[2];
                    if (cons > 1) {
                        console.log("function readCookies() : filterlabel: " + filterlabel);
                        console.log("function readCookies() : filter: " + filter);
                    }
                    //$(".filterlabel-" + filterlabel).addClass("label-active");
                    // parent button active setzen
                    //$('button.filter-' + filter).addClass("button-active");
                    if (filterlabel != "preis" && filterlabel != "orderby") {
                        var filterlabel2 = $('.filterlabel-' + filterlabel + '*[data-filtervalue=' + filter + ']');

                        //console.log("aktion setfilter()")
                        var filter      = $(filterlabel2).data( "filter" );
                        var filtervalue = $(filterlabel2).data( "filtervalue" );
                        if (cons > 1) {
                            console.log("filter: " + filter)
                            console.log("filtervalue: " + filtervalue)
                        }
                        // andere filterlabel gleichen filters deaktivieren
                        $(filterlabel2).addClass("label-active");
                        // parent button active setzen
                        $('button.filter-' + filter).addClass("button-active");

                        var showSetFilter = 1;
                    } else if (filterlabel == "orderby") {
                        var showSetFilter = 1;
                    } else if (filterlabel == "preis") {
                        var showSetFilter = 1;
                    }
                    //console.log("fil: " + ('.filterlabel-'+filterlabel+'*[data-filtervalue='+filter+']'));
                    //console.log(fil);
                }
            }
        }
        if (showSetFilter == 1) {
            if (cons > 1) {
                console.log("Cookies aktiv, Filter setzen");
            }
            checkfilter();
            showSmallFilter();
        }
    }

    if (window.location.search) {
        readUrlFilter();
    } else {
        readCookies();
    }

    function readUrlFilter() {
        let showSetFilter = false;
        const urlParts = URLToArray(window.location.href);

        if (urlParts["filterName"] && urlParts["filterValue"]) {
            const filterNames = urlParts["filterName"].split("|");
            const filterValues = urlParts["filterValue"].split("|");
            for (let i = 0; i < filterNames.length; i++) {
                setFilter(filterNames[i - 1], filterValues[i - 1]);
            }

            showSetFilter = true;
        }

        if (urlParts["minPreis"]) {
            $(".filterauswahl-preis #min").val(urlParts["minPreis"]);
            showSetFilter = true;
        }

        if (urlParts["maxPreis"]) {
            $(".filterauswahl-preis #max").val(urlParts["maxPreis"]);
            showSetFilter = true;
        }

        if (urlParts["minDurchmesser"]) {
            $(".filterauswahl-durchmesser #minDurchmesser").val(urlParts["minDurchmesser"]);
            showSetFilter = true;
        }

        if (urlParts["maxDurchmesser"]) {
            $(".filterauswahl-durchmesser #maxDurchmesser").val(urlParts["maxDurchmesser"]);
            showSetFilter = true;
        }

        if (showSetFilter) {
            checkfilter();
            showSmallFilter();
        }

    }

    function setFilter(filterlabel, filterValue) {
        var filterlabel2 = $('.filterlabel-' + filterlabel + '*[data-filtervalue=' + filterValue + ']');

        var filter      = $(filterlabel2).data( "filter" );
        var filtervalue = $(filterlabel2).data( "filtervalue" );
        if (cons > 1) {
            console.log("filter: " + filter)
            console.log("filtervalue: " + filtervalue)
        }
        // andere filterlabel gleichen filters deaktivieren
        $(filterlabel2).addClass("label-active");
        // parent button active setzen
        $('button.filter-' + filter).addClass("button-active");
    }

    function URLToArray(url) {
        var request = {};
        var pairs = url.substring(url.indexOf('?') + 1).split('&');
        for (var i = 0; i < pairs.length; i++) {
            if(!pairs[i])
                continue;
            var pair = pairs[i].split('=');
            request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
        return request;
    }

    function setFilterCookies(id) {
        if (cons > 0) {
            console.log("function setFilterCookies()");
        }
        Cookies.set(id, 1, { expires: 1 });
    }
    function deleteFilterCookies(id) {
        if (cons > 0) {
            console.log("function deleteFilterCookies()");
        }
        Cookies.remove(id);
    }
    function setFilterCookiePreis(summe, typ) {
        if (cons > 0) {
            console.log("function setFilterCookiePreis()");
        }
        var entryId = $('body').data( "id" );
        if (entryId > 0 && summe > 0) {
            if (cons > 1) {
                console.log("summe: " + summe);
                console.log("typ: " + typ);
            }
            Cookies.remove('fc-' + entryId + '-preis-' + typ);
            Cookies.set('fc-' + entryId + '-preis-' + typ, summe, { expires: 1 });
        }
    }

    // alle filter zurücksetzen - DEAKTIVIERT
    /*
    $('.row-filter .reset').click(function() {
        $( ".productwrapper" ).each(function( index ) {
            $(this).show();
        });

        $('button.filter').removeClass("open");
        $('button.filter').removeClass("button-active");
        $(".btn-cross").removeClass("active");
        $(".label-active").removeClass("label-active");

    });
    */

    // bestimmte filtergruppe aufklappen
    $('button.filtergruppe').on('click', function(e){
        console.log("aktion: .filtergruppe').on('click')")
        // e.stopPropagation();
        $(this).toggleClass("open");
        $('.filter-container').removeClass("filtersmall");

        if ($(this).hasClass("open")) {
            // alle anderen schliessen
            $('button.filter').removeClass("open");
            $('button.filter').find(".btn-cross").removeClass("active");

            // momentanen aufklappen
            $(this).addClass("open");
            $(this).find(".btn-cross").addClass("active");
        } else {
            $(this).find(".btn-cross").removeClass("active");
        }
    });

    // klick auf einzelnes filterlabel (z.b. Messika)
    $('.filterlabel').on('click', function(e){
        console.log("aktion: .filterlabel').on('click')")
        setfilter(this);
    });

    // einzelnen filter setzen
    function setfilter(filterlabel) {
        //console.log("aktion setfilter()")
        var filter      = $(filterlabel).data( "filter" );
        var filtervalue = $(filterlabel).data( "filtervalue" );
        if (cons > 1) {
            console.log("filter: " + filter)
            console.log("filtervalue: " + filtervalue)
        }
        // filterlabel aktivieren / deaktivieren
        // bereits aktiv
        if ($(filterlabel).hasClass("label-active")) {
            $(filterlabel).removeClass("label-active");
            // parent button inactive setzen
            $('button.filter-' + filter).removeClass("button-active");
            var entryId = $('body').data( "id" );
            if(entryId > 0) {
                deleteFilterCookies('fc-' + entryId + '-' + filter + "-fv-" + filtervalue);
            }
        }
        // aktivieren
        else {
            // andere filterlabel gleichen filters deaktivieren
            //$( ".filterlabel-" + filter ).removeClass("label-active");
            $(filterlabel).addClass("label-active");
            // parent button active setzen
            $('button.filter-' + filter).addClass("button-active");
            var entryId = $('body').data( "id" );
            if(entryId > 0 && filter != undefined && filtervalue != undefined) {
                setFilterCookies('fc-' + entryId + '-' + filter + "-fv-" + filtervalue);
            }
        }
        //console.log("checkfilter here: ");
        checkfilter();
    }

    /* welche filter sind gesetzt? */
    function checkfilter(offset = 0, layout = "replace") {
        if (cons > 1) {
            console.log("aktion checkfilter()");
        }
        var filter = "";
        var filtervalue = "";

        // welche filter sind gesetzt
        $( ".productwrapper" ).show();
        $( ".filterlabel" ).each(function( index ) {
            if ($(this).hasClass('label-active')) {

                // z.b. MarkeProduktfeld
                var activeFilter = $(this).data( "filter" );
                // wieviele filter sind active?
                var activeFilterLength = $( ".filterauswahl .filterlabel-" + activeFilter + ".label-active" ).length;
                if (cons > 1) {
                    console.log("activeFilter: " + activeFilter);
                    console.log("activeFilterCount: " + activeFilterLength);
                }
                if (activeFilterLength > 1) {
                    $( ".filterauswahl .filtercounter.filtercounter-" + activeFilter ).html(", +" + (activeFilterLength - 1));
                }

                var filterauswahlContent = ""
                $( ".filterauswahl .filterlabel-" + activeFilter + ".label-active" ).each(function( index ) {

                    var activeSet = $( ".filterauswahl.filterauswahl-" + activeFilter + " .activeFilterString").html();
                    if(activeSet|length > 0) {
                        filterauswahlContent = activeSet + ", " + $(this).data( "filtervalue" );
                    } else {
                        filterauswahlContent = $(this).data( "filtervalue" );
                    }
                    $( ".filterauswahl.filterauswahl-" + activeFilter + " .activeFilterString").html(filterauswahlContent);

                });

                filter = filter + $(this).data( "filter" ) + "|";
                filtervalue = filtervalue + $(this).data( "filtervalue" ) + "|";

                if (cons > 1) {
                    console.log("aktiver filter: " + filter);
                    console.log("aktivee filtervalue: " + filtervalue);
                }
            }
        });

        var productids = $('.button-plus').data("productids");
            var entryid = $('.button-plus').data("entryid");
            var blockid = $('.button-plus').data("blockid");
        var slugmarke = $('.button-plus').data("slugmarke");
        var agrup =     $('.button-plus').data("agrup");
        var wgrup =     $('.button-plus').data("wgrup");
        var limit =     $('.button-plus').data("limit");
        var orderby =   $('.button-plus').data("orderby");

        var productIdStr =   $('.productIdStr').data("productidstr");

        $(this).hide();

        var minPreis =  $( ".minPreis" ).val();
        var maxPreis =  $( ".maxPreis" ).val();

        var minDurchmesser =  $( ".minDurchmesser" ).val();
        var maxDurchmesser =  $( ".maxDurchmesser" ).val();

        var ajaxUrl = "/ajax/loadproducts?productIdStrAjax=" + productIdStr +
            "&limit=" + limit +
            "&offset=" + offset +
            "&orderby=" + orderby +
            "&filterName=" + filter +
            "&filterValue=" + filtervalue +
            "&minPreis=" + minPreis +
            "&maxPreis=" + maxPreis +
            "&minDurchmesser=" + minDurchmesser +
            "&maxDurchmesser=" + maxDurchmesser;

        if (cons == 20) {
            console.log("checkfilter ajaxUrl: " + ajaxUrl);
        }
        let urlString = location.protocol + '//' + location.host + location.pathname;
        let firstChar = "?";

        // Default value: highlights ASC
        if (orderby && orderby !== "highlights ASC")  {
            urlString += firstChar + "orderby=" + orderby;
            firstChar = "&";
        }
        if (filter) {
            urlString += firstChar + "filterName=" + filter;
            firstChar = "&";
        }
        if (filtervalue) {
            urlString += firstChar + "filterValue=" + filtervalue;
            firstChar = "&";
        }
        if (minPreis && minPreis != $( ".minPreis" ).data('real-min-price')) {
            urlString += firstChar + "minPreis=" + minPreis;
            firstChar = "&";
        }
        if (maxPreis && maxPreis != $( ".maxPreis" ).data('real-max-price')) {
            urlString += firstChar + "maxPreis=" + maxPreis;
            firstChar = "&";
        }
        if (minDurchmesser && minDurchmesser != $( ".minDurchmesser" ).data('real-min-diameter')) {
            urlString += firstChar + "minDurchmesser=" + minDurchmesser;
            firstChar = "&";
        }
        if (maxDurchmesser && maxDurchmesser != $( ".maxDurchmesser" ).data('real-max-diameter')) {
            urlString += firstChar + "maxDurchmesser=" + maxDurchmesser;
        }
        history.pushState(null, document.title, urlString);
        loadProducts(ajaxUrl, offset, layout);

        /*
        $( ".productwrapper" ).each(function( index ) {
            if (!$(this).hasClass(filtervalue)) {
                $(this).hide();
            }
        });
        */

    }

    /* ajax loading */
    /* mehr produkte anzeigen */
    $('.button-plus').on('click', function () {
        console.log("aktion button-plus click");
        var limit = $(this).data("limit");
        var offset = $(this).data("offset") + limit;
        console.log("limit: " + limit);
        console.log("offset: " + offset);
        checkfilter(offset, "append");


    });

    /* reihenfolge ändern */
    $('.button-orderby').click(function(e) {

        console.log("aktion button-orderby click");

        $('.button-orderby').removeClass('active');
        $(this).addClass('active');

        var orderby = $(this).data("orderby");

        // set order für more-button
        $('.button-plus').data("orderby", orderby);
        var entryId = $('body').data( "id" );
        if(entryId > 0) {
            deleteFilterCookies('fc-' + entryId + '-orderby-fv-VP-ASC');
            deleteFilterCookies('fc-' + entryId + '-orderby-fv-VP-DESC');
            setFilterCookies('fc-' + entryId + '-orderby-fv-' + orderby);
        }

        /*

        if ($(filterlabel).hasClass("label-active")) {
            $(filterlabel).removeClass("label-active");
            // parent button inactive setzen
            $('button.filter-' + filter).removeClass("button-active");
            var entryId = $('body').data( "id" );
            if(entryId > 0) {
                deleteFilterCookies('fc-' + entryId + '-' + filter + "-fv-" + filtervalue);
            }
        }
        // aktivieren
        else {
            // andere filterlabel gleichen filters deaktivieren
            //$( ".filterlabel-" + filter ).removeClass("label-active");
            $(filterlabel).addClass("label-active");
            // parent button active setzen
            $('button.filter-' + filter).addClass("button-active");
            var entryId = $('body').data( "id" );
            if(entryId > 0 && filter != undefined && filtervalue != undefined) {
                setFilterCookies('fc-' + entryId + '-' + filter + "-fv-" + filtervalue);
            }
        }
        */

        checkfilter(0, "replace");

    });

    /* filter anzeigen */
    $('.button-filter').click(function(event) {
        event.stopPropagation();
        $('.filter-container').toggleClass('active');
        var total = $('.producte-total').data('total');
        $('.button-total span').text(total);

        if ($('.filter-container').hasClass('active')) {
            /* filter schliessen bei click outside */
            $('body').addClass('showfilter');
        } else {
            showSmallFilter();
        }
    });

    /* filter schliessen */
    $('.button-total').click(function(e) {
        $('.filter-container').removeClass('active');
        showSmallFilter();
    });

    /* filter schliessen bei click outside */
    $('body').on('click',function(event){
        if( $('.filter-container').hasClass('active')) {
            $('.filter-container').removeClass('active');
            showSmallFilter();
        }
    });

    $('.filter-container').click(function(event){
        event.stopPropagation();
    });

    function showSmallFilter() {
        $('body').removeClass('showfilter');

        if (cons > 1) {
            console.log("aktion showSmallFilter()");
        }
        var filter = "";
        var filtervalue = "";

        var filtervalueMarkeProduktfeld = "";
        var filtervalueMarkeProduktfeldCounter = 0;
        var filtervaluePlinie = "";
        var filtervaluePlinieCounter = 0;
        var filtervalueZielgruppe = "";
        var filtervalueAgrup = "";
        var filtervalueAgrupCounter = 0;
        var filtervalueMatSchmuck = "";
        var filtervalueMatSchmuckCounter = 0;
        var filtervalueSteinfarbe = "";
        var filtervalueSteinfarbeCounter = 0;
        var filtervaluebuydirect = "";

        var filtervalueWerkart = "";
        var filtervalueWerkartCounter = 0;
        var filtervalueZfunktion = "";
        var filtervalueZfunktionCounter = 0;
        var filtervalueMatGeh = "";
        var filtervalueMatGehCounter = 0;
        var filtervalueMatArmb = "";
        var filtervalueMatArmbCounter = 0;
        var filtervalueDurchmesser = "";
        var filtervaluePreis = "";

        // welche filter sind gesetzt
        $( ".filter-container .filterlabel" ).each(function( index ) {
            if ($(this).hasClass('label-active')) {

                filter = filter + $(this).data( "filter" ) + "|";
                filtervalue = filtervalue + $(this).data( "filtervalue" ) + "|";

                if (cons > 1) {
                    console.log("filter: " + filter);
                    console.log("filtervalue: " + filtervalue);
                }

                var strLength = 35;

                if ($(this).data( "filter" ) == "MarkeProduktfeld") {
                    if (filtervalueMarkeProduktfeld.length == 0) {
                        filtervalueMarkeProduktfeld = "Marke:";
                    }
                    if (filtervalueMarkeProduktfeld.length + $(this).data( "filtervalue" ).length < strLength) {
                        filtervalueMarkeProduktfeld = filtervalueMarkeProduktfeld + " " + $(this).data( "filtervalue" ) + ",";
                    } else {
                        filtervalueMarkeProduktfeldCounter = filtervalueMarkeProduktfeldCounter + 1;
                    }
                }
                else if ($(this).data( "filter" ) == "Plinie") {
                    if (filtervaluePlinie.length == 0) {
                        filtervaluePlinie = "Kollektion:";
                    }
                    if (filtervaluePlinie.length + $(this).data( "filtervalue" ).length < strLength) {
                        filtervaluePlinie = filtervaluePlinie + " " + $(this).data( "filtervalue" ) + ",";
                    } else {
                        filtervaluePlinieCounter = filtervaluePlinieCounter + 1;
                    }
                }
                else if ($(this).data( "filter" ) == "zielgruppe") {
                    if (filtervalueZielgruppe.length == 0) {
                        filtervalueZielgruppe = "Zielgruppe:";
                    }
                    filtervalueZielgruppe = filtervalueZielgruppe + " " + $(this).data( "filtervalue" ) + ",";
                }
                else if ($(this).data( "filter" ) == "Agrup") {
                    if (filtervalueAgrup.length == 0) {
                        filtervalueAgrup = "Schmuckart:";
                    }
                    if (filtervalueAgrup.length + $(this).data( "filtervalue" ).length < strLength) {
                        filtervalueAgrup = filtervalueAgrup + " " + $(this).data( "filtervalue" ) + ",";
                    } else {
                        filtervalueAgrupCounter = filtervalueAgrupCounter + 1;
                    }
                }
                else if ($(this).data( "filter" ) == "MatSchmuck") {
                    if (filtervalueMatSchmuck.length == 0) {
                        filtervalueMatSchmuck = "Material:";
                    }
                    if (filtervalueMatSchmuck.length + $(this).data( "filtervalue" ).length < strLength) {
                        filtervalueMatSchmuck = filtervalueMatSchmuck + " " + $(this).data( "filtervalue" ) + ",";
                    } else {
                        filtervalueMatSchmuckCounter = filtervalueMatSchmuckCounter + 1;
                    }
                }
                else if ($(this).data( "filter" ) == "Steinfarbe") {
                    if (filtervalueSteinfarbe.length == 0) {
                        filtervalueSteinfarbe = "Farbe:";
                    }
                    if (filtervalueSteinfarbe.length + $(this).data( "filtervalue" ).length < strLength) {
                        filtervalueSteinfarbe = filtervalueSteinfarbe + " " + $(this).data( "filtervalue" ) + ",";
                    } else {
                        filtervalueSteinfarbeCounter = filtervalueSteinfarbeCounter + 1;
                    }
                }
                else if ($(this).data( "filter" ) == "buydirect") {
                    filtervaluebuydirect = "Sofort erhältlich";
                }


                else if ($(this).data( "filter" ) == "Werkart") {
                    if (filtervalueWerkart.length == 0) {
                        filtervalueWerkart = "Werk:";
                    }
                    if (filtervalueWerkart.length + $(this).data( "filtervalue" ).length < strLength) {
                        filtervalueWerkart = filtervalueWerkart + " " + $(this).data( "filtervalue" ) + ",";
                    } else {
                        filtervalueWerkartCounter = filtervalueWerkartCounter + 1;
                    }
                }
                else if ($(this).data( "filter" ) == "Zfunktion") {
                    if (filtervalueZfunktion.length == 0) {
                        filtervalueZfunktion = "Funktion:";
                    }
                    var name = $(this).data( "filtervalue" );
                    var nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)

                    if (nameCapitalized == "WeitereKomplizierteFunktionen") {
                        nameCapitalized = "Weitere Komplizierte ...";
                    }
                    if (nameCapitalized == "WeitereZeitzoneGmt") {
                        nameCapitalized = "Weitere Zeitzone / GMT";
                    }
                    if (filtervalueZfunktion.length + nameCapitalized.length < strLength) {
                        filtervalueZfunktion = filtervalueZfunktion + " " + nameCapitalized + ",";
                    } else {
                        filtervalueZfunktionCounter = filtervalueZfunktionCounter + 1;
                    }
                }
                else if ($(this).data( "filter" ) == "MatGeh") {
                    if (filtervalueMatGeh.length == 0) {
                        filtervalueMatGeh = "Material:";
                    }
                    if (filtervalueMatGeh.length + $(this).data( "filtervalue" ).length < strLength) {
                        filtervalueMatGeh = filtervalueMatGeh + " " + $(this).data( "filtervalue" ) + ",";
                    } else {
                        filtervalueMatGehCounter = filtervalueMatGehCounter + 1;
                    }
                }
                else if ($(this).data( "filter" ) == "MatArmb") {
                    if (filtervalueMatArmb.length == 0) {
                        filtervalueMatArmb = "Material Armband:";
                    }
                    if (filtervalueMatArmb.length + $(this).data( "filtervalue" ).length < strLength) {
                        filtervalueMatArmb = filtervalueMatArmb + " " + $(this).data( "filtervalue" ) + ",";
                    } else {
                        filtervalueMatArmbCounter = filtervalueMatArmbCounter + 1;
                    }
                }

            }
        });


        if (filtervalueMarkeProduktfeld.length > 0) {
            filtervalueMarkeProduktfeld = filtervalueMarkeProduktfeld.substring(0, filtervalueMarkeProduktfeld.length - 1);
            if (filtervalueMarkeProduktfeldCounter > 0) {
                filtervalueMarkeProduktfeld = filtervalueMarkeProduktfeld + ", +" + filtervalueMarkeProduktfeldCounter;
            }
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-MarkeProduktfeld" ).addClass("active");
            $( ".minifilter-MarkeProduktfeld span" ).html(filtervalueMarkeProduktfeld);
        }
        if (filtervaluePlinie.length > 0) {
            filtervaluePlinie = filtervaluePlinie.substring(0, filtervaluePlinie.length - 1);
            if (filtervaluePlinieCounter > 0) {
                filtervaluePlinie = filtervaluePlinie + ", +" + filtervaluePlinieCounter;
            }
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-Plinie" ).addClass("active");
            $( ".minifilter-Plinie span" ).html(filtervaluePlinie);
        }
        if (filtervalueZielgruppe.length > 0) {
            filtervalueZielgruppe = filtervalueZielgruppe.substring(0, filtervalueZielgruppe.length - 1);
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-zielgruppe" ).addClass("active");
            $( ".minifilter-zielgruppe span" ).html(filtervalueZielgruppe);
        }
        if (filtervalueAgrup.length > 0) {
            filtervalueAgrup = filtervalueAgrup.substring(0, filtervalueAgrup.length - 1);
            if (filtervalueAgrupCounter > 0) {
                filtervalueAgrup = filtervalueAgrup + ", +" + filtervalueAgrupCounter;
            }
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-Agrup" ).addClass("active");
            $( ".minifilter-Agrup span" ).html(filtervalueAgrup);
        }
        if (filtervalueMatSchmuck.length > 0) {
            filtervalueMatSchmuck = filtervalueMatSchmuck.substring(0, filtervalueMatSchmuck.length - 1);
            if (filtervalueMatSchmuckCounter > 0) {
                filtervalueMatSchmuck = filtervalueMatSchmuck + ", +" + filtervalueMatSchmuckCounter;
            }
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-MatSchmuck" ).addClass("active");
            $( ".minifilter-MatSchmuck span" ).html(filtervalueMatSchmuck);
        }
        if (filtervalueSteinfarbe.length > 0) {
            filtervalueSteinfarbe = filtervalueSteinfarbe.substring(0, filtervalueSteinfarbe.length - 1);
            if (filtervalueSteinfarbeCounter > 0) {
                filtervalueSteinfarbe = filtervalueSteinfarbe + ", +" + filtervalueSteinfarbeCounter;
            }
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-Steinfarbe" ).addClass("active");
            $( ".minifilter-Steinfarbe span" ).html(filtervalueSteinfarbe);
        }
        if (filtervaluebuydirect.length > 0) {
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-buydirect" ).addClass("active");
            $( ".minifilter-buydirect span" ).html(filtervaluebuydirect);
        }

        if (filtervalueWerkart.length > 0) {
            filtervalueWerkart = filtervalueWerkart.substring(0, filtervalueWerkart.length - 1);
            if (filtervalueWerkartCounter > 0) {
                filtervalueWerkart = filtervalueWerkart + ", +" + filtervalueWerkartCounter;
            }
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-Werkart" ).addClass("active");
            $( ".minifilter-Werkart span" ).html(filtervalueWerkart);
        }
        if (filtervalueZfunktion.length > 0) {
            filtervalueZfunktion = filtervalueZfunktion.substring(0, filtervalueZfunktion.length - 1);
            if (filtervalueZfunktionCounter > 0) {
                filtervalueZfunktion = filtervalueZfunktion + ", +" + filtervalueZfunktionCounter;
            }
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-Zfunktion" ).addClass("active");
            $( ".minifilter-Zfunktion span" ).html(filtervalueZfunktion);
        }
        if (filtervalueMatGeh.length > 0) {
            filtervalueMatGeh = filtervalueMatGeh.substring(0, filtervalueMatGeh.length - 1);
            if (filtervalueMatGehCounter > 0) {
                filtervalueMatGeh = filtervalueMatGeh + ", +" + filtervalueMatGehCounter;
            }
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-MatGeh" ).addClass("active");
            $( ".minifilter-MatGeh span" ).html(filtervalueMatGeh);
        }
        if (filtervalueMatArmb.length > 0) {
            filtervalueMatArmb = filtervalueMatArmb.substring(0, filtervalueMatArmb.length - 1);
            if (filtervalueMatArmbCounter > 0) {
                filtervalueMatArmb = filtervalueMatArmb + ", +" + filtervalueMatArmbCounter;
            }
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-MatArmb" ).addClass("active");
            $( ".minifilter-MatArmb span" ).html(filtervalueMatArmb);
        }

        if ( $( ".filter.filter-Durchmesser" ).length ) {
            var minDMset = parseFloat($('.durchmesserRange').data( "mindurchmesser" ));
            var maxDMset = parseFloat($('.durchmesserRange').data( "maxdurchmesser" ));
            var minDMnow = parseFloat($('#minDurchmesser').val());
            var maxDMnow = parseFloat($('#maxDurchmesser').val());
            if (minDMset != minDMnow || maxDMset != maxDMnow) {
                $( ".minifilter-wrapper" ).addClass("active");
                $( ".minifilter-Durchmesser" ).addClass("active");
                $( ".minifilter-Durchmesser span" ).html("Durchmesser: " + minDMnow + "mm - " + maxDMnow + "mm");
            }
        }

        var minPreisset = parseFloat($('.preisRange').data( "minpreis" ));
        var maxPreisset = parseFloat($('.preisRange').data( "maxpreis" ));
        var minPreisnow = parseFloat($('#min').val());
        var maxPreisnow = parseFloat($('#max').val());
        if (minPreisset != minPreisnow || maxPreisset != maxPreisnow) {
            $( ".minifilter-wrapper" ).addClass("active");
            $( ".minifilter-Preis" ).addClass("active");
            $( ".minifilter-Preis span" ).html("Preis: " + minPreisnow + " - " + maxPreisnow);
        }
    }

    $('.remove-minifilter').click(function(e) {
        var removefilter = $(this).data("target");
        // alle filter unsetten
        $( ".filterlabel.filterlabel-" + removefilter ).removeClass("label-active");
        $( ".filtergruppe.filter-" + removefilter ).removeClass("button-active");
        $( ".filtergruppe.filter-" + removefilter ).removeClass("open");
        var entryId = $('body').data( "id" );

        if (removefilter == "Preis") {
            var minPreisset = parseFloat($('.preisRange').data( "minpreis" ));
            var maxPreisset = parseFloat($('.preisRange').data( "maxpreis" ));
            $('#min').val(minPreisset);
            $('#max').val(maxPreisset);
            var $slider = $(".filterauswahl-preis #slider-range");
            $slider.slider("values", 0, minPreisset);
            $slider.slider("values", 1, maxPreisset);

            if (entryId > 0) {
                deleteFilterCookies("fc-"+entryId+"-preis-min");
                deleteFilterCookies("fc-"+entryId+"-preis-max");
            }
        }
        else if (removefilter == "Durchmesser") {
            var minDMset = parseFloat($('.durchmesserRange').data( "mindurchmesser" ));
            var maxDMset = parseFloat($('.durchmesserRange').data( "maxdurchmesser" ));
            $('#minDurchmesser').val(minDMset);
            $('#maxDurchmesser').val(maxDMset);
            var $slider = $(".filterauswahl-durchmesser #slider-range");
            $slider.slider("values", 0, minDMset);
            $slider.slider("values", 1, maxDMset);
        } else {

            var entryId = $('body').data( "id" );
            if (entryId > 0) {
                //console.log("readCookies");
                for (key in Cookies.get()) {
                    if (!key.indexOf("fc-"+entryId+"-"+removefilter+'-fv-')) {
                        console.log(key);
                        console.log("removefilter: " + removefilter);
                        deleteFilterCookies(key);
                    }
                }
            }
        }

        $( ".minifilter-" + removefilter ).removeClass("active");
        checkfilter(0, "replace");
        if ( $( ".minifilter.active" ).length ) {
        } else {
            $('.minifilter-wrapper').removeClass("active");
        }
    });

    /* kleine filter entfernen */
    $('.filterauswahl .remove-button').click(function(e) {
        console.log("aktion remove-button click");
        var removefilter = $(this).data("removefilter");

        // alle filter unsetten
        $( ".filterlabel.filterlabel-" + removefilter ).removeClass("label-active");
        $( ".filtergruppe.filter-" + removefilter ).removeClass("button-active");
        $( ".filtergruppe.filter-" + removefilter ).removeClass("open");

        checkfilter(0, "replace");
    });

    /* produkte per ajax laden */
    function loadProducts(ajaxUrl, offset, layout = "append") {

        console.log("aktion loadProducts()")
        //console.log("ajaxUrl: " + ajaxUrl);

        $.ajax({
            type: "GET",
            url: ajaxUrl,
            success: function(result){
                console.log("aktion loadProducts()::success");
                if (layout == "replace") {
                    $('.row-inhalteMatrix-schmuckstueckUhr').html(result);
                } else {
                    $('.row-inhalteMatrix-schmuckstueckUhr').append(result);
                }
            },
            complete: function () {
                console.log("aktion loadProducts()::complete");
                //console.log("offset: " + offset);
                $('.button-plus').data("offset", offset);
                $('.button-plus').show();
                var total = $('.producte-total').data('total');
                $('.button-total span').text(total);

                var limit = $('.button-plus').data('limit');

                //console.log("total: " + total);
                //console.log("limit: " + limit);
                var numItems = $('.row-inhalteMatrix-schmuckstueckUhr .produktlink').length;
                //console.log("count shown: " + numItems);
                if (numItems >= total) {
                    $('.button-plus').hide();
                } else {
                    $('.button-plus').show();
                }

            }

        });

    }

});